import styled from 'styled-components'
import { colors, sizes } from '@theme'
import A from '@components/A'
import { fontStack } from '../Globals'

export const StyledFooter = styled.footer`
  background-color: ${colors.bg.white};
  width: 100%;
  display: block;
  margin: 0 0 -40px;
  border-top: 1px solid ${colors.bg.lightGray};

  & a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: ${colors.text.black};
    display: block;
    margin: 0px 0px 15px;
  }

  & a:hover {
    text-decoration: underline;
  }
  & h2 {
    padding: 16px 0px 0px;
    font-size: 20px;
    letter-spacing: 0.43px;
    line-height: 25px;
  }
  @media (max-width: ${sizes.small.max}) {
    width: 100%;
    padding: 0 15px;
  }
`

export const FooterRow = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    flex-basis: 50%;

    &:first-child {
      flex-basis: 100%;
    }
  }
`
export const FooterLinks = styled.div`
  border-bottom: 1px solid rgba(39, 37, 31, 0.15);
  display: block;
  padding: 32px 0px 55px;
  width: 100%;
`

export const FooterNavCol = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-bottom: 20px;

  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-decoration: none;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    flex: 0 0 50%;
    max-width: 50%;

    &:first-child {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media (min-width: 992px) {
    flex: 1;
    max-width: none;

    // &:first-child {
    //   padding-right: 188px;
    // }

    // &:last-child {
    //   padding-right: 0;
    // }
  }
`

export const StyledCopyright = styled.div`
  padding-top: 10px;
  border-top: 1px solid rgba(39, 37, 31, 0.15);
  color: #27251f;
  display: block;
  padding: 12px 0px 15px;
  font-family: 'HelveticaNeueLTStd-Lt';
  font-size: 12px;
  line-height: 20px;
  width: 100%;
`

export const CopyrightCol = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  color: ${colors.text.black};
  display: block;
  padding: 12px 0px 15px;
  ${fontStack}
  font-size: 12px;
  line-height: 20px;

  & a {
    display: inline-flex !important;
  }

  & a:hover {
    color: #b4a06e;
  }

  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: 767px) {
    text-align: center;
  }
`

export const CopyrightText = styled(CopyrightCol)`
  @media (max-width: 767px) {
    padding: 12px 0px 0px;
  }
`

export const SitemapTerms = styled(CopyrightCol)`
  text-align: center;
  @media (min-width: 768px) {
    text-align: right;
  }
`

export const RightBorderLink = styled(A)`
  padding: 0px 10px;
  color: #b4a06e;
  font-size: 14px;
  letter-spacing: 0.4px;
  border-right: 1px solid rgba(39, 37, 31, 0.15);
`

export const NoBorderLink = styled(A)`
  padding: 0px 10px;
  color: #b4a06e;
  font-size: 14px;
  letter-spacing: 0.4px;
`

export const SocialButton = styled.a`
  display: inline-block !important;
  background: transparent;
  border: 1px solid #001c54;
  border-right: none;
  border-radius: 0px;
  height: 54px;
  line-height: 60px;
  padding: 0px;
  z-index: 2;

  position: relative;
  text-align: center;
  vertical-align: middle;
  margin: 0px -1px 0px 0px;
  width: 54px;

  &:after {
    display: none;
  }
  & img {
    display: inline-block;
    vertical-align: middle;
  }

  &:hover:after {
    content: '';
    display: block;
    border: 1.5px solid #001c54;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    pointer-events: none;
    z-index: 0;
  }
`
export const SocialList = styled.div`
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 25px;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;

  ${SocialButton}:last-child {
    border-right: 1px solid #001c54;
  }
`

// sra recommends certain css
// keeping all of within one styled div
// to better track together vs diff styled components

export const SRASpacingContainer = styled.div`
  margin-top: 25px;
  max-width: 275px;
  max-height: 163px;

  .inner-sra-required {
    position: relative;
    padding-bottom: 59.1%;
    height: auto;
    overflow: hidden;

    iframe {
      border: 0px;
      margin: 0px;
      padding: 0px;
      background-color: transparent;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
`

export const SocialAndSRAContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
`
