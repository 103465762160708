import React from 'react'
import PropTypes from 'prop-types'
import Stepper from '../Stepper'
import Controls from './components/Controls'
import Slide from './components/Slide'
import {
  StepperContainer,
  StyledCarousel,
  FullWidthWrapper,
  StyledTwoColumnCarousel,
  StyledContentWrapper,
} from './style'
import { HeroBannerWithOverlay } from '../Banner/components/Banner'
import {
  useCarousel,
  useMediaQuery,
  useLocale,
  useTranslatedTerms,
} from '@hooks'
import StyledInsightsSlide from './components/InsightsSlide'
import StyledTwoThirdsSlide from './components/TwoThirdsSlide'
import StyledFullWidthSlide from './components/FullWidthSlide'
import insightsBg from '@assets/images/Site_Banner_Insights_Carousel.png'
import defaultBg from '@assets/images/Homepage_Awards_Bg.webp'
import { sizes } from '@theme'
import { nodeLocaleToEnglishLanguage } from '../../../gatsby/createPagesUtils/nodeHelpers'
import TwoColumnSlide from './components/TwoColumnSlide'
import A from '@components/A'
import TwoColumnCarouselBg from '@assets/images/TwoColumnCarouselBg.png'
import ImageV2 from '@components/ImageV2'
import { Container } from '@components/Common'

const defaultGatsbyBg = {
  image: {
    fluid: {
      src: defaultBg,
    },
  },
}
const defaultInsightsBg = {
  fluid: {
    src: insightsBg,
  },
}

const Carousel = ({
  autoPlay = false,
  controls = false,
  stepper = true,
  align = 'left',
  children,
  slidesPerView = 1,
  type,
  controlTop,
  stepperColor,
  layout,
  interval = 5000,
  ...props
}) => {
  const sm = useMediaQuery(`(max-width: ${sizes.small.max})`)

  const visibleSlides = sm ? 1 : slidesPerView

  const isCarouselActive = sm || children.length > 2

  const numberOfSteps = sm
    ? children.length
    : Math.ceil(children.length / visibleSlides)

  const initialIndex = 0

  const [currIndex, setIndex, next, prev, transitions] = useCarousel(
    children.length,
    numberOfSteps,
    autoPlay,
    initialIndex,
    interval,
    visibleSlides
  )

  const handleStepperIndex = stepIndex => {
    const newIndex = stepIndex * slidesPerView
    setIndex(newIndex)
  }

  const showControls =
    controls && !sm && isCarouselActive && children.length > 1
  const showStepper = stepper && isCarouselActive && children.length > 1

  const StyledWrapper =
    layout === 'two column' ? StyledTwoColumnCarousel : StyledCarousel

  return (
    <StyledWrapper>
      {showControls && (
        <Controls
          controlTop={controlTop}
          next={next}
          prev={prev}
          autoPlay={autoPlay}
        />
      )}

      <StyledContentWrapper>
        {transitions.map(({ item, props, key }) => (
          <Slide key={key} style={props} type={type}>
            {children[item]}
          </Slide>
        ))}
      </StyledContentWrapper>

      {showStepper && (
        <StepperContainer align={align} {...props}>
          <Stepper
            activeStep={
              layout === 'two column' && !sm
                ? Math.ceil((currIndex + 1) / 2)
                : currIndex + 1
            }
            numberOfSteps={numberOfSteps}
            circle
            color="white"
            setIndex={handleStepperIndex}
            stepperColor={stepperColor}
          />
        </StepperContainer>
      )}
    </StyledWrapper>
  )
}

const CarouselWithSlides = ({
  title,
  layout,
  slides,
  heroImage,
  mobileHeight,
  desktopHeight,
  ...rest
}) => {
  const { locale } = useLocale()
  const { getTranslatedTerm } = useTranslatedTerms()
  const display = slide =>
    !slide?.contentDestination?.[0]?.displayOnSites ||
    slide?.contentDestination?.[0]?.displayOnSites?.includes(
      nodeLocaleToEnglishLanguage[locale]
    )
  slides = slides?.filter(slide => display(slide))
  const sm = useMediaQuery(`(max-width: ${sizes.small.max})`)

  switch (layout) {
    case 'fullwidth':
      return (
        <FullWidthWrapper
          mobileHeight={mobileHeight}
          desktopHeight={desktopHeight}
        >
          {slides && slides.length > 0 && (
            <Carousel type="fullwidth" align="center" controls>
              {slides.map(slide => (
                <StyledFullWidthSlide
                  slide={slide}
                  key={slide.id}
                  tag="insight"
                  mobileHeight={mobileHeight}
                  desktopHeight={desktopHeight}
                  {...rest}
                />
              ))}
            </Carousel>
          )}
        </FullWidthWrapper>
      )
    case 'slider':
      return (
        <HeroBannerWithOverlay
          align="left"
          height="md"
          heroImage={defaultGatsbyBg.image}
          text={false}
          gradient={false}
          style={{ maxWidth: '2000px', margin: '0px' }}
        >
          {slides && slides.length > 0 && (
            <Carousel
              {...rest}
              type="slider"
              slidesPerView={2}
              align="center"
              layout={layout}
            >
              {slides.map((slide, i) => (
                <StyledTwoThirdsSlide
                  slides={slides}
                  slide={[slide, slides[i + 1], slides[i + 2]]}
                  key={slide.id}
                  tag="insight"
                />
              ))}
            </Carousel>
          )}
        </HeroBannerWithOverlay>
      )
    case 'two column':
      return (
        <div className="relative h-full flex flex-col">
          <ImageV2
            src={TwoColumnCarouselBg}
            className="absolute inset-0 w-full h-full object-cover z-0"
          />
          <div className="relative z-10 flex flex-col h-full">

            <Container className="text-burgundy pt-[64px] pb-4 text-[18px] w-full font-medium uppercase tracking-widest px-6 md:px-4 lg:px-8 xl:px-0">

              {title}
            </Container>

            {slides && slides.length > 0 && (
              <>
                <div className="z-10 w-full flex justify-center items-center">
                  <Carousel
                    {...rest}
                    type="two column"
                    controls={!sm}
                    slidesPerView={sm ? 1 : 2}
                    align="center"
                    layout={layout}
                    stepperColor={'gray'}
                  >
                    {slides.map((slide, i) => (
                      <TwoColumnSlide
                        title={title}
                        slides={sm ? [slide] : [slide, slides[i + 1]]}
                        key={slide.id}
                      />
                    ))}
                  </Carousel>
                </div>
                <div className="flex justify-center mt-6 mb-12">
                  <A
                    to="/insights"
                    className="text-[18px] font-bold tracking-wider hover:underline hover:underline-offset-2"
                  >
                    View All Insights
                  </A>
                </div>
              </>
            )}
          </div>
        </div>
      )
    default:
      return (
        <HeroBannerWithOverlay
          align="left"
          height="lg"
          heroImage={defaultInsightsBg}
          gradient="blue"
          text={false}
        >
          {slides?.length && (
            <Carousel
              autoPlay
              stepper
              stepperPadding="0px 0px 60px"
              mobileStepperPadding="0px 0px 40px"
              type="default"
            >
              {slides?.map(slide => (
                <StyledInsightsSlide
                  slide={slide}
                  key={slide.id}
                  tag={getTranslatedTerm('Featured Insight')}
                  mobileSlidePadding="max-[450px]:py-[25px] max-[450px]:px-0"
                  slidePadding="py-[50px] px-0"
                />
              ))}
            </Carousel>
          )}
        </HeroBannerWithOverlay>
      )
  }
}
Carousel.propTypes = {
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  stepper: PropTypes.bool,
  align: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default CarouselWithSlides
export { Carousel }
export const InsightsSlide = ({ slide, ...rest }) => (
  <StyledInsightsSlide slide={slide} {...rest} />
)
export const TwoThirdsSlide = ({ slide, ...rest }) => (
  <StyledTwoThirdsSlide slide={slide} {...rest} />
)
export const FullWidthSlide = ({ slide, height, ...rest }) => (
  <StyledFullWidthSlide slide={slide} height={height} {...rest} />
)
